/* Change this file to get your personal Portfolio */

// To change portfolio colors globally go to the  _globalColor.scss file

import emoji from "react-easy-emoji";
import splashAnimation from "./assets/lottie/splashAnimation"; // Rename to your file name for custom animation

// Splash Screen

const splashScreen = {
  enabled: true, // set false to disable splash screen
  animation: splashAnimation,
  duration: 2000 // Set animation duration as per your animation
};

// Summary And Greeting Section

const illustration = {
  animated: true // Set to false to use static SVG
};

const greeting = {
  username: "jetag",
  title: "Hi all, I'm Tejas",
  subTitle: emoji(
    "A passionate Robotics Engineer 🚀 building robotic systems for more than 6 years. I have worked with a variety of tools ranging from Control theory, Navigation planning to Machine Learning, Embedded system design and Mechatronics. Always up for learning cool new stuff!"
  ),
  resumeLink:
    "https://drive.google.com/file/d/1Sf17_1ae__4sinnXcNH_MgrzFHGMtD1T/view?usp=sharing", // Set to empty to hide the button
  displayGreeting: true // Set false to hide this section, defaults to true
};

// Social Media Links

const socialMediaLinks = {
  github: "https://github.com/jetag",
  linkedin: "https://www.linkedin.com/in/robotjet/",
  gmail: "tejas.dce@gmail.com",
  // gitlab: "https://gitlab.com/saadpastea",
  // facebook: "https://www.facebook.com/saad.pasta7",
  // medium: "https://medium.com/@saadpasta",
  // stackoverflow: "https://stackoverflow.com/users/10422806/saad-pasta",
  // Instagram, Twitter and Kaggle are also supported in the links!
  // To customize icons and social links, tweak src/components/SocialMedia
  display: true // Set true to display this section, defaults to false
};

// Skills Section

const skillsSection = {
  title: "What I do",
  subTitle: "CRAZY ROBOTICS ENGINEER WHO WANTS TO CREATE NEW ROBOTS",
  skills: [
    emoji(
      "⚡ Develop efficient and robust robotic systems using C++ & Python"
    ),
    emoji("⚡ Work on hardware and software integration development cycles"),
    emoji(
      "⚡ Create end user interfaces and Documentation websites"
    )
  ],

  /* Make Sure to include correct Font Awesome Classname to view your icon
https://fontawesome.com/icons?d=gallery */

  softwareSkills: [
    {
      skillName: "ROS",
      fontAwesomeClassname: "fas fa-robot"
    },
    {
      skillName: "C++",
      fontAwesomeClassname: "fab fa-css3-alt"
    },
    {
      skillName: "Linux",
      fontAwesomeClassname: "fab fa-linux"
    },
    {
      skillName: "Matlab",
      fontAwesomeClassname: "fas fa-calculator"
    },
    {
      skillName: "PX4",
      fontAwesomeClassname: "fas fa-plane"
    },
    {
      skillName: "Git",
      fontAwesomeClassname: "fab fa-github"
    },
    {
      skillName: "Embedded",
      fontAwesomeClassname: "fas fa-bolt"
    },
    {
      skillName: "Web-dev",
      fontAwesomeClassname: "fas fa-code"
    },
    {
      skillName: "python",
      fontAwesomeClassname: "fab fa-python"
    },
    {
      skillName: "docker",
      fontAwesomeClassname: "fab fa-docker"
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Education Section

const educationInfo = {
  display: true, // Set false to hide this section, defaults to true
  schools: [
    {
      schoolName: "University of Pennsylvania",
      logo: require("./assets/images/upennlogo.png"),
      subHeader: "Master of Science in Electrical Engineering (Robotics)",
      duration: "Aug 2021 - May 2023",
      desc: "Took 10 courses on Robotics, ML, CV and Embedded. Researched on a novel aerial vehicle system and taught a course on Embedded ML ",
      descBullets: [
        "GPA : 3.88/4.0",
        "Outstanding Masters Service Award"
      ]
    },
    {
      schoolName: "Delhi Technological University",
      logo: require("./assets/images/dtulogo.png"),
      subHeader: "Bachelor of Technology in Electrical & Electronics Engineering",
      duration: "Aug 2016 - May 2020",
      desc: "Took 40 courses on Robotics, Control theory, Embedded, ML, and Cosmology. Developed more than 5 robotic systems and published thesis on brain signal eplilepsy detection",
      descBullets: ["GPA : 8.8/10.0",
        "Best Thesis Project Award"]
    }
  ]
};

// Your top 3 proficient stacks/tech experience

const techStack = {
  viewSkillBars: true, //Set it to true to show Proficiency Section
  experience: [
    {
      Stack: "Drones / UAVs", //Insert stack or technology you have experience in
      progressPercentage: "90%" //Insert relative proficiency in percentage
    },
    {
      Stack: "Self Driving Electric Vehicles", //Insert stack or technology you have experience in
      progressPercentage: "85%" //Insert relative proficiency in percentage
    },
    {
      Stack: "Arm Robotics",
      progressPercentage: "80%"
    },
    {
      Stack: "Programming",
      progressPercentage: "90%"
    }
  ],
  displayCodersrank: false // Set true to display codersrank badges section need to changes your username in src/containers/skillProgress/skillProgress.js:17:62, defaults to false
};

// Work experience section

const workExperiences = {
  display: true, //Set it to true to show workExperiences Section
  experience: [
    {
      role: "Robotics Engineer",
      company: "Autoware Foundation",
      companylogo: require("./assets/images/autowarelogo.png"),
      date: "May 2023 – Present",
      desc: "Working on Navigation pipeline, Embedded hardware & Mechatronics for an Autonomous open source electric Gokart.",
      descBullets: [
        "Enabling hardware drivers, communication protocols like CAN, SPI and setting test procedures for electrical subsystems",
      //  "Led the development of the Documentation & website. Implemented GRAPH SLAM,  product CI/CD & Motor feedback control"
      ]
    },
    {
      role: "Course Developer & Teaching Assistant",
      company: "Safe Autonomous Systems Lab",
      companylogo: require("./assets/images/xlablogo.png"),
      date: "June 2022 – May 2023",
      desc: "Developed undergraduate course for more than 50 students and worked on vehicle planning algorithms for self driving cars.",
      descBullets: [
        "Created Edge AI applications such as Keyword Spotting, Person & Gesture detection on a 1MB unicycle robot.",
        // "Delivered 20+ Python and C++ based tutorials on Tensorflow lite for model quantization, maintained Git and Website"
      ]
    },
    {
      role: "Robotics Graduate Research Assistant",
      company: "Sung Robotics Group",
      companylogo: require("./assets/images/havlogo.png"),
      date: "Jan 2022 – May 2023",
      desc: "Worked on a novel bistable aerial vehicle - to be published in Soft Robotics Journal.",
      descBullets: [
        "Enabled control, planning and communication in ROS (C++) for a Hybrid Aerial Vehicle softwinged robot.",
        // "Engineered aggressive flight maneuvers in PX4, worked on spline trajectory generation, contributed to more than 150 flights, analyzed data in Matlab. The system showed 20% improvement in energy efficiency from conventional UAVs"
      ]
    },
    {
      role: "Research Scholar",
      company: "3IT Institute",
      companylogo: require("./assets/images/3it.jpeg"),
      date: "May 2019 – Aug 2019",
      desc: "Worked on a realtime fault detection interface for industrial robots.",
      descBullets: [
        "Developed a fault detection interface to parse robot’s TF data in real time via web sockets to an iOS AR environment.",
        // "Created an AR application to view errors in industrial robots using a smart device by overlaying statistics"
      ]
    },
    {
      role: "Avionics Engineer",
      company: "UAS-DTU",
      companylogo: require("./assets/images/uasdtulogo.png"),
      date: "Aug 2017 – Aug 2018",
      desc: "Developed cool UAVs and worked on the autopilot firmware.",
      descBullets: [
        "Worked on the electronics, communication, and control for 3 fixed wing UAVs, one octacopter and multiple quadcopters",
        // "Designed the subsystems & led the integration of embedded systems (pi & PX4) with the control surfaces, ran sensor       calibrations and power tests on motors and sensors and ran analysis on data. 2nd worldwide for the AUVSI 2018 journal",
        // "Worked on the autopilot firmware, SIL, HIL, comms and conducted more than 30 flight tests throughout the year."
      ]
    }
  ]
};

/* Your Open Source Section to View Your Github Pinned Projects
To know how to get github key look at readme.md */

const openSource = {
  showGithubProfile: "true", // Set true or false to show Contact profile using Github, defaults to true
  display: false // Set false to hide this section, defaults to true
};

// Some big projects you have worked on

const bigProjects = {
  title: "Projects",
  subtitle: "SOME OF MY MOST FUN WORKS",
  projects: [
    {
      image: require("./assets/images/car.png"),
      projectName: "Self driving car",
      projectDesc: "Development of algorithms for head to head racing on a closed circuit and winning them",
      footerLink: [
        {
          name: "Visit Project report",
          url: "https://drive.google.com/file/d/17J6yDrnKzxjwQvwj9k_aVY_xcXEgUjhV/view?usp=drive_link"
        }
        //  you can add extra buttons here.
      ]
    },
    {
      image: require("./assets/images/franka.png"),
      projectName: "Robotic arm manipulation",
      projectDesc: "Development of the fastest pick & place software stack scoring highest points in the competition’s 10 year history.",
      footerLink: [
        {
          name: "Visit Project report",
          url: "https://drive.google.com/file/d/1c4PsHIW0Kh1I48iDtRrgl2Hc4YBiBU4z/view?usp=drive_link"
        }
      ]
    },
    {
      image: require("./assets/images/collision.png"),
      projectName: "Multi agent path planning",
      projectDesc: "Planned collision free paths for centralized swarms in 150 secs using SCP.",
      footerLink: [
        {
          name: "Visit Project report",
          url: "https://drive.google.com/file/d/1pu5swLCLzVDYGZewH33jqqIgBn8tQmd1/view?usp=drive_link"
        }
      ]
    },
    {
      image: require("./assets/images/crazyflie.png"),
      projectName: "Indoor Quadrotor Navigation",
      projectDesc: "Created Control stack for a Crazyflie 2.0 drone. Stood among top 10.",
      footerLink: [
        {
          name: "Visit Project report",
          url: "https://drive.google.com/file/d/1dw8KRItHRTlzzyKd_tg0rAFKrYLv8baf/view?usp=sharing"
        }
      ]
    },
    {
      image: require("./assets/images/drift.png"),
      projectName: "Drifting using RL",
      projectDesc: "Trained a SAC policy to follow a raceline while drifting.",
      footerLink: [
        {
          name: "Visit Project report",
          url: "https://drive.google.com/file/d/1yzQUa70GV8MaOdhNZh_nWjY4G6N3u1jp/view?usp=drive_link"
        }
      ]
    },
    {
      image: require("./assets/images/slam.png"),
      projectName: "SLAM for AMRs",
      projectDesc: "Implemented UKF for 3D drone tracking & SLAM using Particle Filter.",
      footerLink: [
        {
          name: "Visit Project report",
          url: "https://drive.google.com/file/d/1LuWdMvaGWrPqe3NHkfnLdO6PYGzb3caH/view?usp=drive_link"
        }
      ]
    },
    {
      image: require("./assets/images/drake.png"),
      projectName: "Bipedal Walking",
      projectDesc: "Designed an OSC for a planar biped. Solved optimization problem for a planar arm.",
      footerLink: [
        {
          name: "Visit Project report",
          url: "https://drive.google.com/file/d/1zS8t4mq5ktfgMmAi0Bl9N3-ymamRoycZ/view?usp=drive_link"
        }
      ]
    },
    {
      image: require("./assets/images/gokart.jpeg"),
      projectName: "Autonomous Gokart",
      projectDesc: "Implemented GRAPH SLAM, worked on motor calibration and created a simulation modeldc",
      footerLink: [
        {
          name: "Visit Project website",
          url: "https://go-kart-upenn.readthedocs.io/en/latest/tutorial.html"
        }
      ]
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Achievement Section
// Include certificates, talks etc

const achievementSection = {
  title: emoji("Achievements And Certifications 🏆 "),
  subtitle:
    "Achievements, Certifications, Award Letters!",

  achievementsCards: [
    {
      title: "Google Code-In Finalist",
      subtitle:
        "",
      image: require("./assets/images/codeInLogo.webp"),
      imageAlt: "Google Code-In Logo",
      footerLink: [
        {
          name: "Certification",
          url: "https://drive.google.com/file/d/0B7kazrtMwm5dYkVvNjdNWjNybWJrbndFSHpNY2NFV1p4YmU0/view?usp=sharing"
        },
        {
          name: "Award Letter",
          url: "https://drive.google.com/file/d/0B7kazrtMwm5dekxBTW5hQkg2WXUyR3QzQmR0VERiLXlGRVdF/view?usp=sharing"
        },
        {
          name: "Google Code-in Blog",
          url: "https://opensource.googleblog.com/2019/01/google-code-in-2018-winners.html"
        }
      ]
    },
    {
      title: "Google Assistant Action",
      subtitle:
        "Developed a Google Assistant Action JavaScript Guru that is available on 2 Billion devices world wide.",
      image: require("./assets/images/googleAssistantLogo.webp"),
      imageAlt: "Google Assistant Action Logo",
      footerLink: [
        {
          name: "View Google Assistant Action",
          url: "https://assistant.google.com/services/a/uid/000000100ee688ee?hl=en"
        }
      ]
    },

    {
      title: "PWA Web App Developer",
      subtitle: "Completed Certifcation from SMIT for PWA Web App Development",
      image: require("./assets/images/pwaLogo.webp"),
      imageAlt: "PWA Logo",
      footerLink: [
        {name: "Certification", url: ""},
        {
          name: "Final Project",
          url: "https://pakistan-olx-1.firebaseapp.com/"
        }
      ]
    }
  ],
  display: false // Set false to hide this section, defaults to true
};

// Blogs Section

const blogSection = {
  title: "Blogs",
  subtitle:
    "With Love for Developing cool stuff, I love to write and teach others what I have learnt.",
  displayMediumBlogs: "true", // Set true to display fetched medium blogs instead of hardcoded ones
  blogs: [
    {
      url: "https://tinyml-readthedocs.readthedocs.io/en/latest/",
      title: "Develop a undergraduate open source course for Embedded Machine Learning",
      description:
        "Do you want to learn how to build applications for small robotic systems using Machine learning? This course is for you!"
    },
    {
      url: "https://go-kart-upenn.readthedocs.io/en/latest/",
      title: "Develop an autonomous go-kart system for research and product development",
      description:
        "Do you want to build your own gokart? Checkout this amazingly detailed tutorial!"
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Talks Sections

const talkSection = {
  title: "TALKS",
  subtitle: emoji(
    "I LOVE TO SHARE MY LIMITED KNOWLEDGE AND GET A SPEAKER BADGE 😅"
  ),

  talks: [
    {
      title: "Build Actions For Google Assistant",
      subtitle: "Codelab at GDG DevFest Karachi 2019",
      slides_url: "https://bit.ly/saadpasta-slides",
      event_url: "https://www.facebook.com/events/2339906106275053/"
    }
  ],
  display: false // Set false to hide this section, defaults to true
};

// Podcast Section

const podcastSection = {
  title: emoji("Podcast 🎙️"),
  subtitle: "I LOVE TO TALK ABOUT MYSELF AND TECHNOLOGY",

  // Please Provide with Your Podcast embeded Link
  podcast: [
    "https://anchor.fm/codevcast/embed/episodes/DevStory---Saad-Pasta-from-Karachi--Pakistan-e9givv/a-a15itvo"
  ],
  display: false // Set false to hide this section, defaults to true
};

const contactInfo = {
  title: emoji("Contact Me ☎️"),
  subtitle:
    "Discuss a project or just want to say hi?",
  email_address: "tejas.dce@gmail.com"
};

// Twitter Section

const twitterDetails = {
  userName: "twitter", //Replace "twitter" with your twitter username without @
  display: false // Set true to display this section, defaults to false
};

const isHireable = true; // Set false if you are not looking for a job. Also isHireable will be display as Open for opportunities: Yes/No in the GitHub footer

export {
  illustration,
  greeting,
  socialMediaLinks,
  splashScreen,
  skillsSection,
  educationInfo,
  techStack,
  workExperiences,
  openSource,
  bigProjects,
  achievementSection,
  blogSection,
  talkSection,
  podcastSection,
  contactInfo,
  twitterDetails,
  isHireable
};
